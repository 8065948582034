import React, { useCallback } from 'react';
import { Button } from 'app/components/button'
import { Icon } from 'app/components/icon/icon'
import { Image } from 'app/components/image'
import { Space } from 'app/components/space'
import { useFriendContext } from 'app/provider/friend-context'
import { config } from 'app/redux/config'
import { strToBase64 } from 'app/util/helper'
import { Text, View, useSx } from 'dripsy'
import { TouchableOpacity, Image as ImageNative, Platform } from 'react-native'
import { useRouter } from 'solito/router'
import { I18Text } from 'app/provider/i18-provider'
import { makeUseAxiosHook } from 'app/redux/store'
import { useDispatch } from 'react-redux'
import { SET_TOKEN } from 'app/redux/constants'
import { useLogout } from 'app/redux/hooks/use-logout'

export interface NavBarProps {
  user?: any
  logout?: (navigate: any) => void
  clientConfig: any
  isProfileIcon?: boolean
  isBackIcon?: boolean
  onBackClick?: () => void
  isShowFriendIcon?: boolean
  loginSignUpBtn?: boolean
  logoPositionCenter?: boolean
  goToDashBoardButton?: boolean
}
export function NavBar({
  clientConfig,
  user,
  isProfileIcon = true,
  isBackIcon = false,
  onBackClick,
  isShowFriendIcon = false,
  loginSignUpBtn = false,
  logoPositionCenter = true,
  goToDashBoardButton = false,
}: NavBarProps) {
  const sx = useSx()
  const dispatch = useDispatch()
  const { logout } = useLogout()
  const { push, back, replace } = useRouter()
  const { friendRequestCount } = useFriendContext()
  const { handleTokenAuth, loading } = useTokenAuth();

  return (
    <View
      style={[
        sx({
          backgroundColor: '$white',
          height: 60,
          borderBottomColor: '$gray',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          paddingRight: [0, 3],
          position: 'absolute',
          zIndex: 9999,
          paddingX: [20],
          justifyContent: 'space-between',
        }),
      ]}
    >
      {/* Left view */}
      {user && isProfileIcon && !user?.isAnonymous ? (
        <View>
          <TouchableOpacity
            onPress={() => {
              push('/profile/[userId]', `/profile/${user?._id}`)
            }}
            style={{
              width: 60,
              height: 60,
              // backgroundColor:'red',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <Image
              source={
                user?.avatar
                  ? {
                      uri: `${config.baseUrl}/api/v1/images/${strToBase64(
                        user?.avatar
                      )}`,
                    }
                  : require('../../../assets/avatar.png')
              }
              defaultSource={require('../../../assets/avatar.png')}
              style={{
                width: 33,
                height: 33,
                borderRadius: 50,
              }}
              alt="user avatar"
            />
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}

      {isBackIcon ? (
        <View>
          <Button
            onPress={() => {
              if (onBackClick) {
                onBackClick()
              } else back()
            }}
            variantType="text"
            style={{
              height: 60,
              width: 60,
              marginLeft: -20,
              justifyContent: 'center',
            }}
          >
            <Icon name="backArrow" />
          </Button>
        </View>
      ) : (
        <></>
      )}
      {/* Center view */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: isProfileIcon || isBackIcon ? -30 : 0,
          flex: 1,
          ...(logoPositionCenter ? { justifyContent: 'center' } : {}),
        }}
      >
        <ImageNative
          source={clientConfig.logoIcon}
          style={{
            width: 150,
            height: 33,
            borderRadius: 11,
            marginRight: 5,
          }}
          alt="logo"
          resizeMode="contain"
        />
      </View>
      {/* Right view */}
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {Platform.OS === 'web' &&
        user &&
        user?.customerIdentifier &&
        user?.productCode ? (
          <Button
            variantType="contained"
            style={sx({
              width: 150,
              height: 25,
              borderWidth: 0,
              display: ['none', 'none', 'flex'],
            })}
            onPress={() => {
              push('/api-playground')
            }}
          >
            <I18Text onlyString>Api Playground</I18Text>
          </Button>
        ) : (
          <></>
        )}

        {isShowFriendIcon && user?._id && !user?.isAnonymous ? (
          <Button
            variantType="text"
            style={{
              height: 60,
              width: 60,
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              push('/friends-request')
            }}
          >
            <>
              {!!friendRequestCount && (
                <View
                  style={{
                    minWidth: 18,
                    height: 18,
                    backgroundColor: 'red',
                    borderRadius: 50,
                    position: 'relative',
                    top: -11,
                    left: -10,
                    zIndex: 999,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <Text
                    ellipsizeMode="tail"
                    numberOfLines={1}
                    style={{
                      textAlign: 'center',
                      color: '#fff',
                      fontSize: 12,
                      maxWidth: 26,
                      marginTop: 1,
                    }}
                  >
                    {friendRequestCount}
                  </Text>
                </View>
              )}
              <Icon
                name="friends"
                style={{ width: 25, height: 25, position: 'absolute' }}
              />
            </>
          </Button>
        ) : (
          <></>
        )}

        {(!user || user?.isAnonymous) && loginSignUpBtn ? (
          <Space>
            {/* {!user?._id ? (
              <Button
                variantType="outlined"
                style={sx({
                  width: [60, 100],
                  height: [25, 35],
                  paddingX: 0,
                  marginRight: [5, 10],
                })}
                loading={loading}
                onPress={() => {
                  handleTokenAuth()
                }}
              >
                <I18Text onlyString>Try now</I18Text>
              </Button>
            ) : (
              <></>
            )} */}
            <Button
              variantType="outlined"
              style={sx({
                width: [60, 100],
                height: [25, 35],
                paddingX: 0,
                marginRight: [5, 10],
              })}
              onPress={() => {
                if (user?.isAnonymous) {
                  logout({
                    onSuccess() {
                      push('/account/signin')
                    },
                  })
                } else push('/account/signin')
              }}
            >
              <I18Text onlyString>Sign In</I18Text>
            </Button>
            <Button
              variantType="contained"
              style={sx({
                width: [60, 100],
                height: [25, 35],
                paddingX: 0,
              })}
              onPress={() => {
                if (user?.isAnonymous) {
                  logout({
                    onSuccess() {
                      push('/account/signup')
                    },
                  })
                } else push('/account/signup')
              }}
            >
              <I18Text onlyString>Sign Up</I18Text>
            </Button>
          </Space>
        ) : goToDashBoardButton ? (
          <Space>
            <Button
              variantType="outlined"
              style={sx({
                width: [110, 150],
                height: [25, 35],
                paddingX: 0,
                marginRight: [5, 10],
              })}
              onPress={() => {
                push('/dashboard')
              }}
            >
              <I18Text onlyString>Go to dashboard</I18Text>
            </Button>
          </Space>
        ) : (
          <></>
        )}
      </View>
    </View>
  )
}



export const useTokenAuth = () => {
  const dispatch = useDispatch();
  const { replace } = useRouter()
  
  const [{ loading }, getToken] = makeUseAxiosHook('default', {
    manual: true,
  })({
    url: '/api/v1/get-anonymous-jwt-token',
    method: 'POST',
  });

  const handleTokenAuth = useCallback(async () => {
    if (Platform.OS !== 'web') {
      try {
        const { data } = await getToken();
        
        dispatch({
          type: 'SET_TOKEN',
          payload: data.token,
        });

        // Use setTimeout to ensure token is set before navigation
        setTimeout(() => {
          replace('/dashboard');
        }, 100);
        
        return { success: true };
      } catch (error) {
        console.error('Token fetch failed:', error);
        return { success: false, error };
      }
    } else {
      replace('/redirect-dashboard');
      return { success: true };
    }
  }, [dispatch, getToken, replace]);

  return {
    handleTokenAuth,
    loading
  };
};
