import React, { ReactNode, useState, useEffect, useMemo, useCallback, useRef } from 'react'
import {
  Platform,
  SafeAreaView,
  ScrollView,
  View,
  Text,
  Image,
  TouchableOpacity,
  StatusBar,
  ScrollViewProps,
  ViewStyle,
  Modal,
  TouchableWithoutFeedback,
} from 'react-native'
import { SxProp, useSx } from 'dripsy'
import { useRouter } from 'solito/router'
import { Icon } from 'app/components/icon/icon'
import { KeyboardAwareScrollView } from 'app/components/keyboard-aware-scroll-view'
import { connect } from 'react-redux'
import * as globalAction from 'app/redux/actions/global.action'
import { useDim } from 'app/redux/hooks/useDim'
import { useCurrentRoute } from 'app/redux/hooks/use-current-route'
import { NavBar } from 'app/features/layout/navbar'
import { Footer } from 'app/features/layout/footer'
import { Container } from 'app/components/container'
import { useUpload } from '../upload/upload-context'
import FeedSearchDetails from '../feed-search/feed-search-details'

interface SidebarItem {
  key: string
  label: string
  icon: ReactNode
}

interface LayoutProps {
  user?: any
  children?: ReactNode
  logout?: (navigate: any) => void
  isNavBar?: boolean
  isPrivatePage?: boolean
  showFullFooter?: boolean
  loaded?: boolean
  containerStyle?: ViewStyle
  clientConfig?: any
  isProfileIcon?: boolean
  isBackIcon?: boolean
  onBackClick?: () => void
  isShowFriendIcon?: boolean
  scrollEnabled?: boolean
  scrollViewProps?: ScrollViewProps
  navBarComponent?: ReactNode
  mainContainerStyle?: ViewStyle
  subContainerStyle?: SxProp
  loginSignUpBtn?: boolean
  logoPositionCenter?: boolean
  goToDashBoardButton?: boolean
  isSidebarMenu?: boolean
  showSidebar?: boolean
  showRightContent?: boolean
}

const ComponentLayout = ({
  user,
  children,
  logout,
  isNavBar = true,
  isPrivatePage = false,
  showFullFooter = false,
  loaded,
  containerStyle,
  clientConfig,
  isProfileIcon = true,
  isBackIcon,
  onBackClick,
  isShowFriendIcon,
  scrollEnabled = true,
  scrollViewProps = {},
  navBarComponent,
  mainContainerStyle,
  subContainerStyle = {},
  loginSignUpBtn,
  logoPositionCenter,
  goToDashBoardButton,
  isSidebarMenu = true,
  showSidebar = true,
  showRightContent = true,
}: LayoutProps) => {
  const sx = useSx()
  const { currentWidth, height } = useDim()

  const { replace, push } = useRouter()
  const currentRoute = useCurrentRoute()
  const [activeItem, setActiveItem] = useState('dashboard');
  const [showFeedSearchModal, setShowFeedSearchModal] = useState(false);
  const sidebarRefs = useRef<{ [key: string]: TouchableOpacity | null }>({});
  const [modalPosition, setModalPosition] = useState({ top: 100, left: 100 });

  // Add detection for Safari browser
  const isSafari = useMemo(() => {
    if (Platform.OS !== 'web') return false
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1
  }, [])

  // Use useMemo for derived state based on width
  const isMobile = useMemo(() => {
    return Platform.OS !== 'web' || currentWidth < 768
  }, [currentWidth])

  const isOnlyLargeScreen = useMemo(() => {
    return Platform.OS === 'web' && currentWidth >= 1400
  }, [currentWidth])

  const sidebarItems = useMemo(() => [
    { key: 'dashboard', label: 'Home', icon: <Icon name="home" /> },
    { key: 'search', label: 'Search', icon: <Icon name="search" fill={['#000']} style={{ width: 24, height: 24 }} /> },
    { key: 'upload', label: 'Upload', icon: <Icon name="upload" /> },
    { key: 'widgets', label: 'Widgets', icon: <Icon name="widgets" /> },
    { key: 'profile', label: 'Profile', icon: <Icon name="user" /> },
  ], [])

  const { openModal } = useUpload();

  const handleSidebarItemPress = useCallback((key: string) => {
    setActiveItem(key)
    if (key === 'home') {
      push('/dashboard')
    } else if (key === 'search') {
      if (Platform.OS === 'web') {
        const searchRef = sidebarRefs.current['search'];
        if (searchRef) {
          searchRef.measureInWindow((pageX, pageY, width, height) => {
            setModalPosition({ left: pageX + width + 10, top: pageY });
            setShowFeedSearchModal(true);
          });
        }
      } else {
        push('/feed-search')
      }
    } else if (key === 'upload') {
      openModal()
    } else if (key === 'widgets') {
      push('/widgets')
    } else if (key === 'profile') {
      push('/profile/[userId]', `/profile/${user?._id}`)
    } else {
      push(`/${key}`)
    }
  }, [push, user, openModal])

  // Render sidebar content with independent scrolling
  const renderSidebarContent = useCallback(() => {
    return (
      <View
        style={sx({
          position: 'fixed', // Keep sidebar fixed
          left: 0,
          top: 0,
          bottom: 0,
          width: 244,
          borderRightWidth: 1,
          borderRightColor: '#eee',
          backgroundColor: 'white',
          zIndex: 10,
          ...(isSafari && { position: 'fixed' }), // Ensure position works in Safari
        })}
      >
        <View
          style={sx({
            padding: 16,
            alignItems: 'center',
          })}
        >
          <Image
            source={clientConfig.logoIcon}
            style={{
              width: 120,
              height: 33,
              marginRight: 5,
            }}
            resizeMode="contain"
            alt="logo"
          />
        </View>

        {/* Independent scrolling sidebar */}
        <ScrollView
          style={sx({
            height: 'calc(100% - 65px)', // Subtract logo area height
            width: '100%',
            ...(isSafari && { WebkitOverflowScrolling: 'touch' }), // Safari smooth scrolling
          })}
        >
          <View
            style={sx({
              width: '100%',
              paddingBottom: 40, // Add padding at bottom for better scrolling
            })}
          >
            {sidebarItems.map((item, index) => (
              <TouchableOpacity
                key={index}
                ref={(el) => {
                  if (el) {
                    sidebarRefs.current[item.key] = el;
                  }
                }}
                style={sx({
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 16,
                  backgroundColor:
                    activeItem === item.key ? '#f5f5f5' : 'transparent',
                })}
                onPress={() => handleSidebarItemPress(item.key)}
              >
                <View
                  style={sx({
                    marginRight: 12,
                    opacity: activeItem === item.key ? 1 : 0.6,
                  })}
                >
                  {item.icon}
                </View>
                <Text
                  style={sx({
                    color: activeItem === item.key ? '#000' : '#666',
                    fontWeight: activeItem === item.key ? 'bold' : 'normal',
                  })}
                >
                  {item.label}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
        </ScrollView>
      </View>
    )
  }, [sx, clientConfig, sidebarItems, activeItem, handleSidebarItemPress, isSafari])

  // Render mobile bottom navigation
  const renderMobileBottomNav = useCallback(() => {
    return (
      <View
        style={sx({
          position: 'absolute', // Fixed position for mobile nav
          bottom: 0,
          left: 0,
          right: 0,
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          borderTopWidth: 1,
          borderTopColor: '#eee',
          backgroundColor: '#fff',
          paddingVertical: 10,
          paddingBottom: Platform.OS === 'ios' ? 30 : 10,
          zIndex: 20, // Higher than content
          ...(isSafari && { position: 'fixed' }), // Ensure position works in Safari
        })}
      >
        {sidebarItems.map((item, index) => (
          <TouchableOpacity
            key={index}
            style={sx({
              alignItems: 'center',
              justifyContent: 'center',
              padding: 8,
            })}
            onPress={() => handleSidebarItemPress(item.key)}
          >
            <View
              style={sx({
                opacity: activeItem === item.key ? 1 : 0.6,
              })}
            >
              {item.icon}
            </View>
            <Text style={{
              color: activeItem === item.key ? '#000' : '#8C8C8C',
              fontSize: 14,
              marginTop: 2,
              opacity: activeItem === item.key ? 1 : 0.6,
            }} >{item.label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    )
  }, [sx, sidebarItems, activeItem, handleSidebarItemPress, isSafari])

  // Render right side content with independent scrolling
  const renderRightContent = useCallback(() => {
    return (
      <View
        style={sx({
          position: 'fixed', // Keep right sidebar fixed
          right: 0,
          top: 0,
          bottom: 0,
          width: 400,
          borderLeftWidth: 1,
          borderLeftColor: '#eee',
          backgroundColor: 'white',
          zIndex: 10,
          ...(isSafari && { position: 'fixed' }), // Ensure position works in Safari
        })}
      >
        <ScrollView
          style={sx({
            height: '100%',
            width: '100%',
            ...(isSafari && { WebkitOverflowScrolling: 'touch' }), // Safari smooth scrolling
          })}
        >
          <View
            style={sx({
              width: '100%',
              padding: 16,
              paddingBottom: 40, // Add padding at bottom for better scrolling
            })}
          >
            {/* Your right content goes here */}
          </View>
        </ScrollView>
      </View>
    )
  }, [sx, isSafari])

  const handleCloseModal = useCallback(() => {
    setShowFeedSearchModal(false);
  }, []);

  // Render the old layout
  const renderOldLayout = () => {
    return (
      <SafeAreaView
        style={sx({
          flex: 1,
          ...(isSafari && { height: '100%', position: 'relative' }), // Safari fix
        })}
      >
        <View
          style={sx({
            flex: 1,
            display: 'flex',
            overflow: isSafari ? 'auto' : 'hidden', // Changed overflow for Safari
            height: isSafari ? '100%' : undefined, // Use percentage for Safari
            minHeight: isSafari ? '100%' : [height + 10, height], // Adjust for Safari
            backgroundColor: '$white',
          })}
        >
          {isNavBar ? (
            navBarComponent ? (
              navBarComponent
            ) : (
              <NavBar
                user={user}
                logout={logout}
                clientConfig={clientConfig}
                isProfileIcon={isProfileIcon}
                isBackIcon={isBackIcon}
                onBackClick={onBackClick}
                isShowFriendIcon={isShowFriendIcon}
                loginSignUpBtn={loginSignUpBtn}
                logoPositionCenter={logoPositionCenter}
                goToDashBoardButton={goToDashBoardButton}
              />
            )
          ) : (
            <></>
          )}
          <View
            style={[
              sx({
                flex: 1,
                display: 'flex',
                flexDirection: 'row-reverse',
                marginTop: isNavBar ? 60 : 0,
                justifyContent: 'center',
                alignItems: 'center',
                ...(isSafari && { position: 'relative', flexGrow: 1 }), // Safari fix
              }),
              mainContainerStyle
            ]}
          >
            {scrollEnabled ? (
              <KeyboardAwareScrollView
                contentContainerStyle={{
                  flexGrow: 1,
                  ...(isSafari && { minHeight: '100%' }) // Safari fix
                }}
                nestedScrollEnabled={scrollEnabled}
                scrollEnabled={scrollEnabled}
                style={[
                  sx({
                    flex: 1,
                    height: isSafari ? 'auto' : '100%', // Safari fix
                    width: '100%',
                    maxWidth: 768,
                    overflow: isSafari ? 'auto' : 'visible', // Safari fix
                    ...subContainerStyle
                  }),
                ]}
              // {...scrollViewProps}
              >
                <View
                  style={[
                    sx({
                      flex: 1,
                      paddingHorizontal: [8, 16],
                      paddingVertical: [2, 4],
                      ...(isSafari && { minHeight: 'auto' }), // Safari fix
                    }),
                    containerStyle,
                  ]}
                >
                  {children}
                </View>
                {showFullFooter && <Footer clientConfig={clientConfig} />}
              </KeyboardAwareScrollView>
            ) : (
              <Container
                style={sx({
                  flex: 1,
                  height: isSafari ? 'auto' : '100%', // Safari fix
                  width: '100%',
                  backgroundColor: '$white',
                  maxWidth: 768,
                  ...subContainerStyle
                })}
              >
                <View
                  style={[
                    sx({
                      minHeight: isSafari ? 'auto' : height - 100, // Safari fix
                      flex: 1,
                      paddingHorizontal: [8, 16],
                      paddingVertical: [2, 4],
                    }),
                    containerStyle,
                  ]}
                >
                  {children}
                </View>
                {showFullFooter && <Footer clientConfig={clientConfig} />}
              </Container>
            )}
          </View>
        </View>
      </SafeAreaView>
    )
  }

  // Render the new sidebar layout with single main scroll
  const renderSidebarLayout = () => {
    return (
      <SafeAreaView
        style={sx({
          flex: 1,
          backgroundColor: '#fff',
          ...Platform.select({
            web: {
              height: isSafari ? '-webkit-fill-available' : '100vh', // Safari fix
              overflow: 'hidden', // Hide overflow at root level
            },
          }),
        })}
      >
        <StatusBar barStyle="dark-content" />

        {/* Fixed position sidebars */}
        {showSidebar && !isMobile && renderSidebarContent()}
        {showRightContent && isOnlyLargeScreen && renderRightContent()}

        {/* Main container with padding for fixed sidebars */}
        <View
          style={sx({
            height: '100vh',
            width: '100%',
            paddingLeft: !isMobile && showSidebar ? 244 : 0, // Account for left sidebar
            paddingRight: isOnlyLargeScreen && showRightContent ? 400 : 0, // Account for right sidebar
            ...(isSafari && {
              WebkitOverflowScrolling: 'touch',
              height: '-webkit-fill-available',
            }),
          })}
        // contentContainerStyle={{
        //   paddingBottom: isMobile ? 80 : 20, // Account for mobile bottom nav
        // }}
        >
          <View
            style={[
              sx({
                padding: [0, 20],
                height: '100%',
                flex: 1,
                minHeight: isMobile ? 'calc(100vh - 80px)' : 'calc(100vh - 40px)', // Account for padding
                ...(Platform.OS !== 'web' && {
                  maxHeight: [height - 150, height - 20],
                  minHeight: [height - 20],
                }),
                ...(isSafari && {
                  minHeight: 'calc(-webkit-fill-available - 40px)',
                }),
              }),
              containerStyle,
            ]}
          >
            {children}
          </View>
        </View>

        {/* Mobile Bottom Navigation */}
        {isMobile && renderMobileBottomNav()}
        {/* React Native Modal */}
        <Modal
          visible={showFeedSearchModal}
          transparent
          animationType="fade"
          onRequestClose={() => setShowFeedSearchModal(false)}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              justifyContent: 'center', // ✅ Center the modal properly
              alignItems: 'center',
            }}
            activeOpacity={1}
            onPress={() => setShowFeedSearchModal(false)}
          >
            <TouchableWithoutFeedback>

              <View
                style={sx({
                  position: 'absolute',
                  top: modalPosition.top,
                  left: modalPosition.left,
                  backgroundColor: 'white',
                  padding: 15,
                  borderRadius: 0,
                  width: 450,
                  elevation: 5,
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.2,
                  shadowRadius: 4,
                  height: '80%', // ✅ Use percentage instead of vh
                })}
              >
                <FeedSearchDetails handleCloseModal={handleCloseModal} />
              </View>
            </TouchableWithoutFeedback>

          </TouchableOpacity>
        </Modal>
      </SafeAreaView>
    )
  }

  useEffect(() => {
    if (isSidebarMenu && currentRoute) {
      const profileKey = currentRoute.split('/')[1] || 'dashboard';
      if (activeItem !== profileKey) {
        setActiveItem(profileKey)
      }
    }
  }, [currentRoute, isSidebarMenu, activeItem])

  useEffect(() => {
    // Exit if the app is not fully loaded
    if (!loaded) return;

    // Check if the current route is the login page
    const isLoginPage = currentRoute === '/account/signin';

    // Redirect to login page if:
    // 1. The page is private.
    // 2. The user is not logged in (user is null or undefined).
    // 3. The current route is not the login page.
    if (isPrivatePage && !user?._id && !isLoginPage) {
      console.log('Redirecting to login page...');
      if (Platform.OS === 'web') {
        // On web, include the current route as a "continue" parameter
        replace(`/account/signin?continue=${currentRoute}`);
      } else {
        // On mobile, redirect directly to the login page
        replace('/account/signin');
      }
    }
  }, [user, currentRoute, isPrivatePage, loaded, replace]);

  // Choose which layout to render based on isSidebarMenu prop
  return isSidebarMenu ? renderSidebarLayout() : renderOldLayout()
}

const mapStateToProps = ({ user, loaded, clientConfig }: any) => ({
  user,
  loaded,
  clientConfig,
})

const Layout = connect(mapStateToProps, {
  ...globalAction,
})(ComponentLayout)

export default Layout