import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    View,
    Text,
    Image,
    TextInput,
    TouchableOpacity,
    FlatList,
    ActivityIndicator,
    SafeAreaView,
    Platform,
    Pressable
} from 'react-native';
import { useSx } from 'dripsy';
import { debounce } from 'lodash';
import { makeUseAxiosHook } from 'app/redux/store';
import { Icon } from 'app/components/icon/icon';
import { useRouter } from 'solito/router';

const FeedSearchDetails = ({ handleCloseModal = () => { } }) => {
    const categories = ['Biryani', 'Paneer masala', 'Gulam Jamun', 'Gajar Hal'];
    const [searchText, setSearchText] = useState('');
    const { push } = useRouter()

    const sx = useSx();

    const [{ loading, data }, fetchData] = makeUseAxiosHook('default', { manual: true })({
        url: `/api/v1/feed-search`,
        method: 'GET',
    });

    // Debounced search handler
    const handleSearch = useCallback(
        debounce((text: any) => {
            setSearchText(text);
        }, 500),
        []
    );

    // Call API when searchText changes
    useEffect(() => {
        fetchData({ params: { search: searchText } });
    }, [searchText]);

    return (
        <SafeAreaView style={{ flex: 1, padding: 10 }}>
            {/* Search Input (Fixed) */}
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#f0f0f0',
                    borderRadius: 20,
                    paddingHorizontal: 10,
                    marginBottom: 10,
                }}
            >
                <Icon height={18} width={18} name='feedSearch' />
                <TextInput
                    placeholder="Search"
                    onChangeText={handleSearch}
                    style={[
                        {
                            flex: 1,
                            height: 40,
                            fontSize: 16,
                            borderColor: '#E8E6FA',
                            borderRadius: 20,
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            marginLeft: 3
                        },
                        sx({ flex: 1, color: '#000' }),
                        Platform.OS === 'web' ? sx({ outline: 'none' }) : {},
                    ]}
                />
            </View>

            {/* Category Filters (Fixed) */}
            <FlatList
                horizontal
                data={categories}
                keyExtractor={(item, index) => `category-${index}`}
                showsHorizontalScrollIndicator={false}
                style={{ maxHeight: Platform.OS === 'web' ? 50 : 20, marginBottom: 15 }}
                renderItem={({ item }) => (
                    <TouchableOpacity
                        style={{
                            borderRadius: 6,
                            borderWidth: 1,
                            padding: 10,
                            borderColor: '#3C3C432E',
                            margin: 2,
                            maxHeight: 30,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Text
                            style={sx({
                                color: '#262626',
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                fontSize: '14px',
                            })}
                        >
                            {item}
                        </Text>
                    </TouchableOpacity>
                )}
            />

            {/* Image Grid (Scrollable) */}
            <View style={{ flex: 1 }}>
                <FeedSearchList loading={loading} data={data?.data || []} push={push} handleCloseModal={handleCloseModal} />
            </View>
        </SafeAreaView>
    );
};



export default FeedSearchDetails;



const FeedSearchList = ({ data, loading, push, handleCloseModal }) => {
    const loadingImagesRef = useRef({});
    const [forceUpdate, setForceUpdate] = useState(false);

    const handleImageLoadStart = (index: any) => {
        loadingImagesRef.current[index] = true;
        setForceUpdate((prev) => !prev);
    };

    const onPressImage = (item: any) => {
        if (handleCloseModal) {
            handleCloseModal()
        };

        push('/feed/[uploadId]', `/feed/${item?.object_id}`)
    };

    const handleImageLoad = (index: any) => {
        loadingImagesRef.current[index] = false;
        setForceUpdate((prev) => !prev);
    };

    return (
        <FlatList
            data={loading ? [] : data || []} // ✅ Show loader when loading
            keyExtractor={(item, index) => `image-${index}-${item?.object_id}`}
            numColumns={3}
            contentContainerStyle={{
                paddingBottom: Platform.OS === 'web' ? 20 : 79,
                flexGrow: 1,
            }}
            extraData={forceUpdate} // Trigger update when loading state changes
            showsVerticalScrollIndicator={true} // ✅ Enable vertical scrolling
            style={{ flex: 1 }} // ✅ Allow the list to take full available height
            ListEmptyComponent={loading ? (
                <ActivityIndicator size="large" color="#000" />
            ) : (
                <Text style={{ textAlign: 'center', padding: 20 }}>No results found</Text>
            )} // ✅ Show loader or message if no data
            renderItem={({ item, index }) => (
                <Pressable style={{ width: '33%', height: 100, margin: 2, position: 'relative' }}
                    onPress={() => onPressImage(item)}
                >
                    {loadingImagesRef.current[index] && (
                        <ActivityIndicator
                            size="small"
                            color="#000"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: [{ translateX: -10 }, { translateY: -10 }],
                            }}
                        />
                    )}
                    <Image
                        source={{ uri: item.url }}
                        onLoadStart={() => handleImageLoadStart(index)}
                        onLoad={() => handleImageLoad(index)}
                        style={{ width: '100%', height: '100%', borderRadius: 0 }}
                        resizeMode="cover"
                    />
                </Pressable>
            )}
        />
    );
};
